table.table.dataTable {
    border-collapse: collapse !important;

    th {
        color: black;
    }

    td.options{
        font-size: 10px;
        a{
            margin: 0 1em;
        }
        text-align: right;
    }

    .contactes {
        display:block;
        line-height: 130%;
        font-size: .875em;

        a {
            color: grey;
        }
    }
    .teatre {
        display:block;
        line-height: 130%;
        font-size: .875em;
    }
}