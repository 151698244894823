
.navbar {
    .navbar-brand {
       img {
           max-width: 50px;
       }
    }
    #dropdownuser {
        text-transform: none;
        i {
           font-size: 2em;
        }
    }
}

.nav-tabs {
    border-bottom: 1px solid #dee2e6;
    .nav-link.active {
        border-color: $primary;
    }
}
