.content{
    background-color: #fff;
    padding: 2em;
    margin-bottom: 1em;
    form.edit-form{
        label{
            display: block;
        }
    }
    form.edit-form input, form.edit-form textarea, form.edit-form select{
        width: 100%;
        padding: .24em;
    }
    form.edit-form input[type="submit"]{
        width: auto;

        background: #4285f4 16px 50% no-repeat;

        color:#fff;
        border-radius: 2px;
        padding: 8px 36px;
        cursor: pointer;
        margin: 1em 0;
        text-transform: uppercase;
        border: none;
        &:hover{
            background-color: #558ff1;
            box-shadow: 0 2px 1px -1px rgba(66,133,244,.2), 0 1px 1px 0 rgba(66,133,244,.14), 0 1px 3px 0 rgba(66,133,244,.12);
        }

    }
    form.edit-form textarea{
        min-height: 150px;
    }
    .items{
        padding-bottom: 2em;
    }

}
a.btn{
    border: 1px solid #dfdfdf;
    color: #4285f4;

}
a.btn.add{
    margin-left:3em;
}
.btn-options{
    a{
      margin-left:3em;
    }
}