main.container {
    margin-top: 90px;
    margin-bottom: 90px;
    h1.page-header {
        border-bottom: 1px solid #e7e7e7;
        margin-bottom: 2rem;

        small {
            color: #ccc;
            font-size: 70%;
        }
        a:hover{
           text-decoration:none;
        }
        a.btn {
            padding-top: 8px;
        }


    }

}


.list-people {
    list-style-type: none;
    margin: 1em 0;
    padding: 0;

    li {
        border-bottom: 1px solid #ccc;
        margin-bottom: 1em;

        h5 {
            margin: 0;
            padding: 0;
        }

        p {
            strong {
                display:block;
                margin-bottom: .5em;
            }
        }
    }
}